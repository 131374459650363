import { useEffect } from "react";

const ArticleInfoContainer = ({ articleId, slug, editId, landingPage }) => {
  useEffect(() => {
    //? if it's landing page, remove localstorage, this will reset it to website root
    if (landingPage) {
      localStorage.removeItem("articleInfo");
    }
    //? if not landing page, assign the localStorage again
    else {
      const articleInfo = localStorage.getItem("articleInfo");
      if (articleInfo) {
        localStorage.setItem(
          "articleInfo",
          JSON.stringify({ articleId: articleId, slug: slug, editId: editId })
        );
      } else {
        localStorage.setItem(
          "articleInfo",
          JSON.stringify({ articleId: articleId, slug: slug, editId: editId })
        );
      }
    }
  }, []);
};

export default ArticleInfoContainer;
